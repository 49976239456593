<template>
  <b-card :show="loading" rounded="sm" no-fade>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
                <b-button variant="primary" router-link to="/clockify/add-project" v-if="$Can('customer_create')">
                  <span class="text-nowrap">Add Project</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :items="listProjects"
          :no-local-sorting="true"
          selectable
          select-mode="single"
          responsive
          :small="true"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :per-page="perPage"
          @row-selected="onRowSelected"
        >
          <template #cell(name)="data"> {{ data.item.name ? data.item.name : 'N/A' }} </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item :to="{ name: 'clockify-edit-project', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="sendToArchive(data.item.id)" v-if="data.item.archived == false">
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Archive </span>
              </b-dropdown-item>

              <b-dropdown-item @click="sendToActive(data.item.id)" v-if="data.item.archived == true">
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Activate </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <a
          class="ml-2 mb-2 mr-2 float-right text-primary"
          v-if="archived == false"
          @click="
            archived = true;
            getClockifyData();
          "
          >Show archived records</a
        >
        <a
          class="ml-2 mb-2 mr-2 float-right text-primary"
          v-else
          @click="
            archived = false;
            getClockifyData();
          "
          >Show active records</a
        >
      </b-overlay>
    </b-card>
  </b-card>
</template>

<script>
('bootstrap-vue');
import { BCard, BCardBody, BRow, BCol, BButton, BAlert, BCardHeader, BCardTitle } from 'bootstrap-vue';
import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import _ from 'lodash';
import vSelect from 'vue-select';
import router from '@/router';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BCard,
    BButton,
    BCardBody,
    BCardHeader,
    BCardTitle,

    vSelect,
  },

  data() {
    return {
      where: router.currentRoute.name,
      archived: false,
      listProjects: [],
      perPage: 10,
      totalRows: 0,
      filter: null,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      loading: true,

      tableColumns: [
        { key: 'name', label: 'Project', class: 'text-left', thStyle: 'width:30%' },
        { key: 'clientName', label: 'Client', class: 'text-left', thStyle: 'width:60%' },
        { key: 'actions', label: 'Actions', class: 'text-center', thStyle: 'width:10%' },
      ],
    };
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  created() {
    this.getClockifyData();
  },

  methods: {
    sendToArchive(id) {
      axiosIns
        .get('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects/' + id, {
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
          },
        })
        .then((response) => {
          // response.data.balanceTable = response.data.balanceTable.reverse();
          var data = response.data;

          data.archived = true;

          axiosIns
            .put('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects/' + id, data, {
              headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
              },
            })
            .then((response) => {
              this.getClockifyData();
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
          // router.push({ name: 'quote-list' });
        });
    },

    sendToActive(id) {
      axiosIns
        .get('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects/' + id, {
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
          },
        })
        .then((response) => {
          // response.data.balanceTable = response.data.balanceTable.reverse();
          var data = response.data;

          data.archived = false;

          axiosIns
            .put('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects/' + id, data, {
              headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
              },
            })
            .then((response) => {
              this.getClockifyData();
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
          // router.push({ name: 'quote-list' });
        });
    },

    onRowSelected(item) {
      router.push({ name: 'clockify-edit-project', params: { id: item[0].id } });
    },

    getClockifyData() {
      this.loading = true;

      var tempData = {
        'page-size': this.perPage,
        'sort-column': 'NAME',
        archived: this.archived,
        page: this.currentPage,
      };

      axiosIns
        .get('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects', {
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
          },
          params: tempData,
        })
        .then((response) => {
          this.listProjects = response.data;

          axiosIns
            .get('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects', {
              headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
              },
              params: {
                'page-size': 5000,
                archived: this.archived,
              },
            })
            .then((response) => {
              this.totalRows = response.data.length;
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    search() {
      this.loading = true;

      var tempData = {
        archived: this.archived,
        name: this.filter,
      };

      axiosIns
        .get('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects', {
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
          },
          params: tempData,
        })
        .then((response) => {
          this.listProjects = response.data;
          this.totalRows = this.listProjects.length;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });

      this.loading = false;
    },
  },

  mounted() {},
  watch: {
    currentPage: {
      handler: function(val, before) {
        this.getClockifyData();
      },
    },

    perPage: {
      handler: function(val, before) {
        this.getClockifyData();
      },
    },

    filter: _.debounce(function(newVal) {
      this.search();
    }, 500),
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';

.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
